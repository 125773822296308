<template>
<el-form ref="form" label-width="80px">
    <el-form-item :label="msg('关键字')">
        <el-input v-model="key" :placeholder="msg('添加后,只能进行修改')"></el-input>
    </el-form-item>
    <el-form-item v-for="item in resources" :key="item.langId" :label="item.langName">
        <el-input v-model="item.msg" :placeholder="msg('显示内容')" ></el-input>
    </el-form-item>
    <div style="text-align:center;">
        <el-button type="primary" size="mini"  @click="onSubmit"><i class="el-icon-check"></i><span>{{msg('确定')}}</span></el-button>
        <el-button  size="mini" v-on:click="$emit('closeDg')">{{msg('取消')}}</el-button>
    </div>
</el-form> 
</template>

<script>
export default {
  name: 'lanEdit',
  computed:{
      key: {
          get(){
              return this.resName? this.resName : this.keyName;
          },
          set(value){
              this.resName = value;
          }
      }, 
  },
  data(){
        return{
            resName:null
        }
    }, 
  props:{
      resources:Array,
      keyName:String
  },
  mounted: function(){
        // var _this = this;
        // if(this.resources != null){
        //     _this.id = this.resources[0].id;
        // }
        // if(this.languages.length == 0){
        //     this.$axios.post(this.$kit.api.languageList,{length: 999, start:0})
        //         .then(function(result){
        //             if(result.status){
        //                 var data = result.data;
        //                 for(var i=0; i<data.length; i++){
        //                     _this.languages.push(data[i]);
        //                 }
        //             }
        //         });
        // }
        // if(this.resources.length == 0){
        //     this.$axios.get(this.$kit.api.getRes+"?id="+this.resource.id)
        //         .then(function(result){
        //             if(result.status){
        //                 var data = result.data;
        //                 for(var i=0; i<data.length; i++){
        //                     _this.resources.push(data[i]);
        //                 }
        //             }
        //         });
        // }
  },
  methods:{
    onSubmit(){
        console.log("aa",this.msg);
        var url = this.$kit.api.resourceUpdata;
        // if(this.resources[0].id==null){
        //     url = this.$kit.api.resourceCreate;
        // }else{
        //     url = this.$kit.api.resourceUpdata;
        // }

        var _this = this;
        var res = [];
        for(var i=0;i<_this.resources.length;i++){
            var id = _this.resources[i].id;
            res.push({id:id==null?null:id,languageId:_this.resources[i].langId,name:_this.key,msg:_this.resources[i].msg})
        }

        this.$axios.post(url, res)
            .then(function(result){
                if(result.status){
                    _this.$alert(result.msg || _this.msg('更新成功'), _this.msg('提示'), {
                        confirmButtonText: _this.msg('确定'),
                        callback: action => {
                            if(result.status){
                                _this.$emit("closeDg", true);
                            }
                        }
                    });
                }else{
                    _this.$message(result.msg || _this.msg('更新失败'));
                }
            });
    },
    clear(){
        this.key = null;
    }
  }
}
</script>

<style class="scss" scoped>


</style>
