<template>
<div class="content-panel">
  <el-row :gutter="10" type="flex" justify="space-between" align="middle" class="breadcrumb-wrap">
    <el-col :span="20">
      <el-breadcrumb separator="/">
        <el-breadcrumb-item :to="{ path: '/' }">{{msg('首页')}}</el-breadcrumb-item>
        <el-breadcrumb-item>{{msg('语言设置')}}</el-breadcrumb-item>
      </el-breadcrumb>
    </el-col>
    <el-col :span="4" style="text-align:right;">
      <el-button type="success" size="small" icon="el-icon-refresh" @click="refresh(true)" circle></el-button>
    </el-col>
  </el-row>

  <el-row :gutter="10">
      <el-col :xs="24" :sm="10" :lg="10" :xl="8" >
        <div>
        <div class="row-wrap" :style="{height: isMinScreen? 'auto':(docHeight-52)+'px'}" style="margin-left:-5px;">
          <el-table :data="languages" style="width: 100%" size="mini" ref="dataTable"  stripe @row-click="selectRow">
            <el-table-column prop="id" label="" min-width="70"></el-table-column>
            <el-table-column prop="label" label="" min-width="90"></el-table-column>
            <el-table-column prop="name" label="" min-width="90"></el-table-column>
            <el-table-column
                align="right">
                <template slot="header">
                    <el-button type="primary" size="mini" @click="addlan()">
                        <i class="el-icon-circle-plus-outline" style="display:inline-block;margin-right:4px;"></i>
                        <span>{{msg('添加')}}</span>
                    </el-button>
                </template>
                <template slot-scope="scope">
                    <el-button type="primary" icon="el-icon-edit" size="mini" circle @click.stop="editLan(scope.row)"></el-button>
                    <el-button type="danger" icon="el-icon-delete" size="mini"  circle @click.stop="deleteLan(scope.row)"></el-button>
                </template>
                </el-table-column>
          </el-table>
        </div>
      </div>
    </el-col>
    <el-col :xs="24" :sm="14" :lg="14" :xl="16" >
      <div>
        <div class="row-wrap" style="display:flex; justify-content: space-between; margin-right:-5px;" >
            <el-breadcrumb separator="/">
            </el-breadcrumb>
          <el-button type="primary" size="mini" @click="addResource()">
            <i class="el-icon-circle-plus-outline" style="display:inline-block;margin-right:4px;"></i>
            <span>{{msg('添加')}}</span>
          </el-button>
        </div>
        <div class="row-wrap" :style="{height: isMinScreen? 'auto':(docHeight-52)+'px'}" style="margin-right:-5px;">
          <el-row :gutter="10"  class="query-wrap">
            <el-col :xs="24" :sm="8" :lg="6" :xl="4">
                <el-input  :placeholder="msg('关键字')" v-model="query.name" size="small">
                  <i slot="prefix" class="el-input__icon el-icon-tickets"></i>
                </el-input>
            </el-col>
            <el-col :xs="24" :sm="8" :lg="6" :xl="4">
                <el-input  :placeholder="msg('内容')" v-model="query.msg" size="small">
                  <i slot="prefix" class="el-input__icon el-icon-chat-dot-square"></i>
                </el-input>
            </el-col>
            <el-col :span="2" style="text-align:right;">
              <el-button-group>
                <el-button type="success" @click="getResources()" size="mini">
                  <i class="el-icon-search" style="display:inline-block;margin-right:4px; "></i>
                  <transition name="el-fade-in-linear">
                    <span v-show="!isMinScreen">{{msg('搜索')}}</span>
                  </transition>
                </el-button>
              </el-button-group>
            </el-col>
          </el-row>
          <el-table :data="resources" style="width: 100%" size="mini" ref="dataTable"  stripe>
            <el-table-column prop="id" :label="msg('ID')" min-width="70"></el-table-column>
            <el-table-column prop="languageId" :label="msg('语言名')" min-width="70"></el-table-column>
            <el-table-column prop="name" :label="msg('关键字')" min-width="100"></el-table-column>
            <el-table-column prop="msg" :label="msg('内容')" min-width="100" ></el-table-column>
            <el-table-column prop="" :label="msg('操作')" min-width="70" :fixed="isMinScreen?'right':false">
              <template slot-scope="scope">
                <el-button type="primary" icon="el-icon-edit" size="mini" circle @click.stop="editResource(scope.row)"></el-button>
              </template>
            </el-table-column>
          </el-table>
          <el-pagination id="xpagination"
            @size-change="handleSizeChange"
            @current-change="handleCurrentChange"
            :background="true"
            :current-page="currentPage"
            :page-sizes="[10, 20,50, 100, 200, 300, 400]"
            :page-size="query.length"
            layout="total, sizes, prev, pager, next, jumper"
            :total="total">
          </el-pagination>
        </div>
      </div>
    </el-col>
  </el-row>

  <el-dialog :title="dglogTitle" :visible.sync="dialogTableVisible" :fullscreen="isMinScreen">
    <lan-edit :language="currLan" v-on:closeDg="closeEditDialog" ref="lanEdit"/>
  </el-dialog>
  <el-dialog :title="dglogTitle" :visible.sync="dialogTableVisible1" :fullscreen="isMinScreen">
    <res-edit :resources="currRes" :keyName="keyName" v-on:closeDg="closeEditDialog1" ref="resEdit"/>
  </el-dialog>
</div>
</template> 

<script>
import LanEdit from "./I18nEdit.vue";
import ResEdit from "./I18nResourceEdit.vue";

export default {
    name:'language',
    components: {
        LanEdit:LanEdit,
        ResEdit:ResEdit
    },
    mounted:function(){
      this.getLanguages();
    },
    data(){
        return{
            query:{  //查询条件
                start: 0,
                length: 10,
            },
            currentPage: 1,   //分页的当前页
            languages: [],   //语言列表
            resources: [],   //语言设置列表
            curr:null,
            dgGrangMenuVisiable: false,
            dialogTableVisible:false,
            dialogTableVisible1:false,
            dglogTitle: "",
            total: 0,//总记录数,
            currLan: {id: null, label: null, name: null, defaulted: null},   //当前列表中选中的id,
            currRes:[],
            keyName:"",
        }
    }, 
    computed: {
        isMinScreen: {  //是否小屏幕，浏览器
            get() {
            return this.$store.state.isMinScreen;
            }
        },
        docHeight: {  //表格的高度
            get() {
            var height =  this.$store.state.docHeight;
            return height - 160;
            }
        }
    },
    methods:{
        refresh: function(refresh){  //刷新列表
            this.$kit.clearQuery(this.query);
            this.getResources(refresh);
        },
        getLanguages:function(){//获取语言列表
            var _this = this;
            var param = this.$kit.rebuildQuery(this.query);
            
            this.axios.post(this.$kit.api.languageList, param).then(function (result) {
                if(result.status){
                    if(_this.languages.length>0)
                        _this.languages.splice(0, _this.languages.length);
                    
                    var roleDatas = result.data;

                    for(var i=0; i<roleDatas.length; i++){
                        var item = roleDatas[i];
                        if(item.created){
                            item.created = item.created.substring(0,10);
                        }

                        _this.languages.push(item);
                    } 

                }else{
                    _this.$message(result.msg);
                }
                
            }).catch(function (error) {
                console.error(error);
            });
        },
        getResources:function(){
            var _this = this;
            var param = this.$kit.rebuildQuery(this.query);
            if(this.curr && this.curr.id){
              param["languageId"] = this.curr.id;
            }
            this.axios.post(this.$kit.api.resourceList, param).then(function (result) {
                if(result.status){
                    if(_this.resources.length>0)
                        _this.resources.splice(0, _this.resources.length);
                    
                    _this.total = result.data.recordsTotal;
                    var roleDatas = result.data.data;

                    for(var i=0; i<roleDatas.length; i++){
                        var item = roleDatas[i];
                        if(item.created){
                            item.created = item.created.substring(0,10);
                        }

                        _this.resources.push(item);
                    } 

                }else{
                    _this.$message(result.msg);
                }
                
            }).catch(function (error) {
                console.error(error);
            });
        },
        closeEditDialog(type){  //编辑弹窗关闭后的回调事件
            if(type){
                this.getLanguages();
            }
            this.dialogTableVisible = false;
        },
        closeEditDialog1(type){  //编辑弹窗关闭后的回调事件
            if(type){
                this.getLanguages();
                this.getResources();
            }
            this.$refs.resEdit.clear();
            this.dialogTableVisible1 = false;
        },
        selectRow(language, column, event){
          this.curr = language;
          this.getResources();
        },
        addlan:function(){//添加语言
            this.currLan = {id: null, label: null, name: null, defaulted: null};
            this.dglogTitle = this.msg('添加语言');
            this.dialogTableVisible = true;
        },
        editLan(row, type){
            this.currLan = row;
            this.dglogTitle = this.msg('添加语言');
            this.dialogTableVisible = true;
        },
        addResource:function(){
          var _this = this;
          _this.currRes = [];
            this.$axios.get(this.$kit.api.getRes+"?id=")
                .then(function(result){
                    if(result.status){
                        var data = result.data;
                        for(var i=0; i<data.length; i++){
                            _this.currRes.push(data[i]);
                        }
                    }
              });
            this.dglogTitle = this.msg('添加语言内容');
            this.keyName = null;
            this.dialogTableVisible1 = true;
        },
        editResource(row, type){
           var _this = this;
           _this.currRes = [];
            this.$axios.get(this.$kit.api.getRes+"?id="+row.id)
                .then(function(result){
                    if(result.status && result.data.length>0){
                      var data = result.data;
                        _this.keyName = data[0].name;
                        if(data.length == _this.languages.length){
                          for(let i=0; i<data.length; i++){
                              _this.currRes.push(data[i]);
                          }
                        }else{

                          for(let i=0; i<_this.languages.length; i++){
                            var lan = _this.languages[i];
                            var item = data.find(d => d.languageId == lan.id);
                            if(item){
                              _this.currRes.push(item);
                            }else{
                              _this.currRes.push({id: null, langId: lan.id, langName: lan.label, name: _this.keyName, msg: _this.keyName});
                            }
                          }
                        }
                    }
              });
            this.dglogTitle = this.msg('编辑语言内容');
            this.dialogTableVisible1 = true;
        },
        deleteLan(row){
            var _this = this;
            this.$confirm(this.fmt('确定要删除《%s》吗?', [row.label]), this.msg('提示'), {
                confirmButtonText: this.msg('确定'),
                cancelButtonText: this.msg('取消'),
                type: 'warning'
            }).then(() => {
                _this.$axios.post(_this.$kit.api.languageDelete, [row.id])
                .then(function(result){
                    _this.$message(result.msg || _this.msg('删除成功'));
                    _this.refresh(true);
                });
            });

        },
        handleSizeChange(val) {  //分页的每页数据变化后的回调事件
          //console.log(`每页 ${val} 条`);
          this.query.length = val;
          this.query.start = 0;
          this.getResources();
        },
        handleCurrentChange(val) {   //分页的当前页改变后的回调事件
          //console.log(`当前页: ${val}`);
          this.query.start = this.query.length * (val -1);
          this.getResources();
        },
    }
}
</script>