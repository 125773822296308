<template>
<el-form ref="form" :model="language" label-width="80px">
    <el-form-item :label="msg('显示名')">
        <el-input v-model="language.label" :placeholder="msg('显示名称，如：简体中文')"></el-input>
    </el-form-item>
    <el-form-item :label="msg('语言代码')">
        <el-input v-model="language.name" :placeholder="msg('语言代码，如：zh-cn')"></el-input>
    </el-form-item>
    <el-form-item :label="msg('是否默认')">
        <el-switch style="display: block; margin-top: 9px;" v-model="language.defaulted"
            active-color="#ff4949"  inactive-color="#13ce66"
            active-text="否" inactive-text="是">
        </el-switch>
    </el-form-item>
  
    <div style="text-align:center;">
        <el-button type="primary" size="mini"  @click="onSubmit"><i class="el-icon-check"></i><span>{{msg('确定')}}</span></el-button>
        <el-button  size="mini" v-on:click="$emit('closeDg')">{{msg('取消')}}</el-button>
    </div>
</el-form> 
</template>

<script>
export default {
  name: 'lanEdit',
  computed:{
      mode: {
          get(){
              return this.id==null;
          }
      }
  },
  props:{
      language:Object
  },
  methods:{
    onSubmit(){
        if(this.language.defaulted){
            this.language.defaulted=1;
        }else{
            this.language.defaulted=0;
        }

        if(this.language.label == null || this.language.name == null){
             this.$message(this.msg('显示名和语言代码不能为空'));
             return;
        }

        var url = null;
        if(this.language.id==null){
            url = this.$kit.api.languageCreate;
        }else{
            url = this.$kit.api.languageUpdata;
        }

        var _this = this;
        this.$axios.post(url, this.language)
            .then(function(result){
                if(result.status){
                    _this.$alert(result.msg || (_this.id==null? _this.msg("创建成功"): _this.msg('更新成功')), _this.msg('提示'), {
                        confirmButtonText: _this.msg('确定'),
                        callback: action => {
                            if(result.status){
                                _this.$emit("closeDg", true);
                            }
                        }
                    });
                }else{
                    _this.$message(result.msg || (_this.id==null? _this.msg('创建失败'):_this.msg('更新失败')));
                }
               
            });
    }
  }
}
</script>

<style class="scss" scoped>


</style>
